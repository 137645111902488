import React from "react";
import "./Home.css";
import ImageSlider from "../components/ImageSlider";
import selectedDishes from "../data/selectedDish"; // Import the selected dishes data
import Link from "../components/Link";

const Home = () => {
  const images = [
    {
      imgURL: process.env.PUBLIC_URL + "/蘇澳港直送深海魚.jpg",
      imgAlt: "img-1",
    },
    {
      imgURL: process.env.PUBLIC_URL + "/10人桌菜-2.jpg",
      imgAlt: "img-2",
    },
    {
      imgURL: process.env.PUBLIC_URL + "/招牌菜.jpg",
      imgAlt: "img-3",
    },
  ];

  return (
    <>
      <div className="home-container">
        <div className="menu-image-container">
          <img src={process.env.PUBLIC_URL + "/2025尾牙.jpg"} alt="尾牙菜單" />
        </div>
        <div className="home-image-container">
          <ImageSlider>
            {images.map((image, index) => (
              <img key={index} src={image.imgURL} alt={image.imgAlt} />
            ))}
          </ImageSlider>
        </div>

        {/* <div className="menu-introduction">
          <div className="menu-feature">
            <h2>菜單介紹</h2>
            <p className="menu-feature-text">
              #江浙菜 #海鮮料理 #上海菜 #包廂桌菜 #現流深海魚 #個人套餐
              #廚房衛生
            </p>
          </div>
          <div className="menu-options">
            <div className="menu-option">
              <img src={process.env.PUBLIC_URL + "/f1.jpg"} alt="定食套餐" />
              <p>定食套餐</p>
            </div>
            <div className="menu-option">
              <img src={process.env.PUBLIC_URL + "/f1.jpg"} alt="單點菜單" />
              <p>單點菜單</p>
            </div>
            <div className="menu-option">
              <img src={process.env.PUBLIC_URL + "/f1.jpg"} alt="桌菜菜單" />
              <p>桌菜菜單</p>
            </div>
          </div>
        </div> */}

        <div className="info-section">
          <div className="info-block private-room-text">
            <h2>包廂介紹</h2>
            <div className="room-description-container">
              <p>
                每間包廂都經過精心設計，融合現代與傳統元素，讓您在享受美食的同時，亦能感受到獨特的文化氛圍。無論是商務會議、家庭聚餐還是慶祝活動，我們的包廂都能滿足您的需求，並為您打造一個難忘的用餐體驗。
              </p>
            </div>
            <Link href="/room-menu" className="button-link">
              查看包廂菜單
            </Link>
          </div>
          <div className="info-block private-room-image">
            <img
              src={process.env.PUBLIC_URL + "/room1.jpg"}
              alt="晶櫻燴包廂照片"
            />
          </div>
        </div>

        <div className="selected-dishes">
          <h2>精選佳餚</h2>
          <div className="food-grid">
            {selectedDishes.map((dish, index) => (
              <div key={index} className="food-box">
                <img src={dish.src} alt={`food-${index + 1}`} />
                <div className="overlay">
                  <h3>{dish.name}</h3>
                  <p>{dish.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
